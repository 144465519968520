"use strict";

/* ==========================================================================
Main
========================================================================== */
/**
 * Description :
 * Test - Scripts de base
 * Auteur :
 * - CEF / FU
 * Historique :
 * - 2020-04-02 : Création
 * - 2021-08-03 : Modif FX pour compatibilité IE
 * Sommaire :
 * |x| dom
 * |x| social
 * |x| tiles
 */

(function () {
  // |x| dom
  // Tous les éléments du dom nécessaires sont stockés dans cet objet
  //
  var dom = {
    //articleItems: [...document.querySelectorAll('.tiles__article__list li')],
    //productItems: [...document.querySelectorAll('.tiles__product__list li')],
    articleItems: Array.prototype.slice.call(document.querySelectorAll('.tiles__article__list li')),
    productItems: Array.prototype.slice.call(document.querySelectorAll('.tiles__product__list li')),
    socialContainer: document.querySelector('.social'),
    socialButton: document.querySelector('.social__button')
  };
  //gestion du collapse des boutons de partage
  var social = {
    onClick: function onClick() {
      // Au clic sur le bouton on change de classe pour rendre visible les enfants
      dom.socialButton.addEventListener('click', function () {
        dom.socialContainer.toggleAttribute('data-open');
      });
    },
    onResize: function onResize() {
      if (window.innerWidth <= 640) {
        dom.socialContainer.dataset.enable = true;
      }
      window.addEventListener('resize', function () {
        if (window.innerWidth <= 640) {
          dom.socialContainer.dataset.enable = true;
        } else {
          dom.socialContainer.dataset.enable = false;
          dom.socialContainer.removeAttribute('data-open');
        }
      });
    },
    init: function init() {
      if (dom.socialContainer) {
        social.onResize();
        social.onClick();
      }
    }
  };
  //Pour étendre le click à toutes la surface des tuiles
  var tiles = {
    onClick: function onClick() {
      //const allTiles = [...dom.articleItems, ...dom.productItems];
      var allTiles = dom.articleItems.concat(dom.productItems);
      if (allTiles.length > 0) {
        allTiles.forEach(function (el) {
          el.addEventListener('click', clickableTile);
          function clickableTile(e) {
            console.log(e);
            //console.log(e.path[0].nodeName);
            console.log(e.target.nodeName);
            this.removeEventListener('click', clickableTile); //on supprime l'elementListner pour ne pas que ça boucle !
            //on teste si l'élément cliqué n'est pas un lien, car sinon, le lien est cliqué "2 fois" et remonte en double dans les statistiques
            //si l'élément clqué est un lien, on a rien à faire
            if (e.target.nodeName != 'A') {
              var tileLink = this.querySelector('a');
              tileLink.click();
            }
          }
        });
      }
    },
    init: function init() {
      tiles.onClick();
    }
  };
  //gestion des faux menus select de navigation (espaces / applications / groupes)
  var footerSelect = {
    onLoad: function onLoad() {
      //const selects = [...document.querySelectorAll('.selectNav')];
      var selects = Array.prototype.slice.call(document.querySelectorAll('.selectNav'));
      selects.map(function (el) {
        footerSelect.onClick(el);
      });
      footerSelect.notOnClick();
    },
    onClick: function onClick(target) {
      target.addEventListener('click', function (e) {
        e.stopPropagation();
        footerSelect.openCloseMenu(target);
      });
    },
    notOnClick: function notOnClick() {
      document.addEventListener('click', function (e) {
        var targetEl = e.target;
        if (targetEl.classList.contains('selectNav')) {
          return;
        } else {
          footerSelect.closeAll();
        }
      });
    },
    openCloseMenu: function openCloseMenu(target) {
      var list = target.querySelector('ul');
      var trigger = target.querySelector('button');
      var test = trigger.getAttribute('aria-expanded');
      if (list !== undefined) {
        if (test === 'true') {
          trigger.setAttribute('aria-expanded', 'true');
          list.removeAttribute('hidden');
        } else {
          trigger.setAttribute('aria-expanded', 'false');
          list.setAttribute('hidden', 'hidden');
        }
      }
    },
    closeAll: function closeAll() {
      //const selects = [...document.querySelectorAll('.selectNav')];
      var selects = Array.prototype.slice.call(document.querySelectorAll('.selectNav'));
      selects.map(function (el) {
        var list = el.querySelector('ul');
        var trigger = el.querySelector('button');
        trigger.setAttribute('aria-expanded', 'false');
        list.setAttribute('hidden', 'hidden');
      });
    },
    init: function init() {
      footerSelect.onLoad();
    }
  };
  var formAnchor = {
    onLoad: function onLoad() {
      var target = document.querySelector('[data-anchor]') || null;
      if (target === null) {
        return;
      } else {
        var targetPos = target.getBoundingClientRect().top;
        var header = document.querySelector('.header__prospection') || null;
        var headerPos = header !== null ? header.offsetHeight : 0;
        var triggers = document.querySelectorAll("[href='#".concat(target.dataset.anchor, "']"));
        triggers.forEach(function (item) {
          return item.addEventListener('click', function (e) {
            e.preventDefault();
            window.scrollTo(0, targetPos - headerPos);
          });
        });
      }
    },
    init: function init() {
      formAnchor.onLoad();
    }
  };
  social.init();
  tiles.init();
  footerSelect.init();
  formAnchor.init();
})();